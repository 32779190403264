import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/browser-check.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.0_@opentelemetry+api@1.8.0_react-dom@19.0.0_react@19.0_ij4yz2lawbfeyf7b3eo7zbgv4q/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.8.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.8.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.8.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.8.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Aboreto\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-aboreto\"}],\"variableName\":\"aboreto\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
